import React, { useEffect, useRef, useState } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import * as firebaseui from 'firebaseui';
import 'firebaseui/dist/firebaseui.css';

const FirebaseAuth = ({ uiConfig, firebaseAuth, className, uiCallback }) => {
    const [userSignedIn, setUserSignedIn] = useState(false);
    const elementRef = useRef(null);
    const navigate = useNavigate();

    useEffect(() => {
        // Get or Create a firebaseUI instance.
        const firebaseUiWidget = firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(firebaseAuth);
        if (uiConfig.signInFlow === 'popup')
            firebaseUiWidget.reset();

        // We track the auth state to reset firebaseUi if the user signs out.
        const unregisterAuthObserver = onAuthStateChanged(firebaseAuth, (user) => {
            if (!user && userSignedIn)
                firebaseUiWidget.reset();
            setUserSignedIn(!!user);

            if (user) {
                // User is signed in, save email context
                localStorage.setItem('userEmail', user.email);

                // Show pop-up alert
                alert(`El usuario ${user.email} ha iniciado sesión.`);

                // Redirect to the previous page
                navigate(-1);
            }

        });

        // Trigger the callback if any was set.
        if (uiCallback)
            uiCallback(firebaseUiWidget);

        // Render the firebaseUi Widget.
        // @ts-ignore
        firebaseUiWidget.start(elementRef.current, uiConfig);
        

        return () => {
            unregisterAuthObserver();
            firebaseUiWidget.reset();
        };
    }, [uiConfig, firebaseAuth, uiCallback, userSignedIn]);

    return <div id="firebaseui-auth-container" className={className} ref={elementRef} />;
};

export default FirebaseAuth;
